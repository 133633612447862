.contact {
    padding: 70px 0;
    text-align: center;
    background-color: #00A2C1;
}

.h2 {
    position: relative;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    margin: 0 0 50px 0;
    font-size: 1.5rem;
}

.text {
    max-width: 704px;
    margin: 0 auto 50px;
    color: #fff;
}

.text p {
    letter-spacing: .05em;
}

.text p a {
    color: #fff;
    margin: 0 2px;
}

.more {
    display: block;
    background-color: #fff;
    color: #000;
    width: 250px;
    height: 46px;
    line-height: 46px;
    margin: 0 auto;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .contact {
        padding-top: 110px;
    }

    .h2 {
        font-size: 1.25rem;
    }

    .h2::before {
        content: '';
        width: 1px;
        height: 30px;
        background-color: #fff;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
    }

    .text p {
        font-size: .75rem;
    }

    .more {
        font-size: .875rem;
    }
}