.about {
    margin-top: 201px;
}

.container {
    padding: 0 82px;
    margin-bottom: 100px;
}

.container:nth-child(even) {
    position: relative;
}

.container:nth-child(even)::before {
    content: '';
    position: absolute;
    top: 200px;
    right: 0;
    width: 76%;
    height: 700px;
    background-color: #DAF2F6;
    z-index: -1;
}

.content {
    display: flex;
}

.container:first-child .content {
    margin-bottom: 116px;
}

.container:nth-child(odd) .content {
    flex-direction: row-reverse;
}

.about .img {
    width: 670px;
}

.about .img p {
    margin: 0 0 8px 0;
    letter-spacing: .05em;
}

.about img {
    width: 100%;
    display: block;
    margin-bottom: 30px;
}

.h2 {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    font-weight: normal;
    margin: 0 0 100px 0;
}

.h2::before {
    content: '';
    height: 50px;
    width: 1px;
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
}

.h3 {
    font-size: 1.5rem;
    writing-mode: vertical-rl;
    margin: 0 0 0 64px;
    letter-spacing: 5px;
    font-weight: normal;
}

.container:nth-child(odd) .h3 {
    margin: 0 64px 0 0;
}

.cyan {
    color: #00A2C1;
}

.whiteWrap {
    position: relative;
}

.whiteWrap::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .1;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .about {
        margin-top: 140px;
    }

    .h2 {
        font-size: 1.25rem;
        margin-bottom: 50px;
    }

    .h2::before {
        height: 30px;
        top: -40px;
    }

    .container {
        padding: 0;
    }

    .container:nth-child(even)::before {
        top: 110px;
        width: 277px;
        height: 533px;
    }

    .h3 {
        font-size: 1rem;
        margin: 50px 0 0 30px;
    }

    .container:nth-child(odd) .h3 {
        margin: 0 30px 0 0;
    }

    .about .img {
        width: 260px;
    }

    .about .img p {
        font-size: .75rem;
    }

    .container .img p {
        padding-left: 8px;
    }

    .container:nth-child(odd) .img p {
        padding-right: 8px;
    }
}