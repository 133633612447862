.instagram {
    margin-bottom: 70px;
}

.h2 {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    margin: 0 0 70px 0;
    display: inline-block;
    padding-left: 82px;
}

.h2::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -170px;
    width: 150px;
    height: 1px;
    background-color: #000;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    width: 20%;
}

.card a {
    position: relative;
    display: flex;
    width: auto;
    height: 250px;
}

.card img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: cover no-repeat;
}

.more {
    background-color: #00A2C1;
    text-align: center;
    width: 20%;
    height: 62px;
    line-height: 62px;
    color: #fff;
    margin-left: auto;
    text-decoration: none;
    font-size: 1.125rem;
}

@media screen and (max-width: 768px) {
    .h2 {
        font-size: 1.25rem;
        margin-bottom: 50px;
        padding-left: 16px;
    }

    .h2::before {
        height: 30px;
        top: -40px;
    }

    .card {
        width: 50%;
    }

    .card a {
        height: 200px;
    }

    .more {
        width: 50%;
        height: 30px;
        line-height: 30px;
        font-size: .875rem;
    }
}