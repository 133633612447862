body {
  line-height: 1.6;
  font-family: 'Shippori Mincho', serif;
  margin: 0;
}

.fadeIn {
  animation-name: fadeIn;
  animation-fill-mode: backwards;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
  animation-direction: normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Swiper style */
.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

.swiper-pagination {
  text-align: left !important;
  left: 16px !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border-radius: unset !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #00A2C1 !important;
}