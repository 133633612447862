.container {
    display: flex;
    justify-content: center;
    padding: 0 82px;
}

.imgs {
    position: relative;
}

.img {
    position: relative;
    width: 460px;
}

.img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .2;
}

.img img {
    display: block;
    width: 100%;
}

.img:last-child {
    position: absolute;
    top: 100px;
    left: 100px;
}

.tx {
    position: relative;
    width: 528px;
    margin-left: 130px;
    color: #fff;
    z-index: 5;
}

.h2 {
    font-size: 1.5rem;
    margin: 0 0 50px 0;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
}

.p p {
    margin: 0 0 16px 0;
    letter-spacing: .05em;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0;
        flex-direction: column;
    }

    .img {
        width: 358px;
        max-width: 100%;
    }

    .img:last-child {
        top: 50px;
        left: unset;
        right: 0;
    }

    .tx {
        width: 100%;
        margin: 100px 0 0 0;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .h2 {
        font-size: 1.25rem;
        color: #000;
        margin-bottom: 30px;
    }

    .p p {
        font-size: .875rem;
        color: #000;
    }
}