.opening_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 9999;
}

.opening_image img {
    width: 300px;
}

.fadeIn {
    animation-name: fadeIn;
    animation-fill-mode: backwards;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-delay: 0.5s;
    animation-direction: normal;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}