.mainVisual {
    position: relative;
}

.mainVisual__img {
    width: 70%;
    margin-left: auto;
}

.mainVisual__img img {
    display: block;
    width: 100%;
}

.mainVisual__cta {
    position: absolute;
    right: 0;
    bottom: -31px;
    background-color: #00A2C1;
    text-align: center;
    width: 266px;
    height: 62px;
    line-height: 62px;
    z-index: 5;
}

.mainVisual__cta a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

.whiteWrap {
    position: relative;
}

.whiteWrap::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .3;
}

@media screen and (max-width: 768px) {
    .mainVisual__img {
        width: 76%;
    }

    .mainVisual__cta {
        bottom: 0;
        width: 100%;
        height: 45px;
        line-height: 45px;
        position: fixed;
        z-index: 10;
    }
}