.lineup {
    margin: 100px 0;
}

.h2 {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    padding-left: 82px;
    margin: 0 0 50px 0;
    display: inline-block;
}

.h2::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -170px;
    width: 150px;
    height: 1px;
    background-color: #000;
}

.container {
    display: flex;
    padding: 0 82px;
}

.card {
    width: calc(100% / 3);
    margin-right: 50px;
}

.card:last-child {
    margin-right: 0;
}

.card:nth-child(2) {
    margin-top: 50px;
}

.card:nth-child(3) {
    margin-top: 100px;
}


@media screen and (max-width: 768px) {
    .h2 {
        font-size: 1.25rem;
        padding-left: 16px;
    }

    .h2::after {
        width: 100px;
        right: -120px;
    }

    .container {
        padding: 0 16px;
        overflow: auto;
    }

    .card {
        width: 100%;
        margin-right: 20px;
    }

    .card:last-child {
        margin-right: 0;
    }

    .card:nth-child(2) {
        margin-top: 0;
    }

    .card:nth-child(3) {
        margin-top: 0;
    }
}