.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: #F9F9FA;
}

.logo img {
    height: 48px;
}

@media screen and (max-width: 768px) {
    .header {
        height: 45px;
    }

    .logo img {
        height: 30px;
    }
}