.footer {
    padding: 16px 0;
    background-color: #CDCDCD;
}

.footer p {
    text-align: center;
    font-size: .75rem;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .footer p {
        font-size: .6875rem;
    }
}