.feature {
    padding: 100px 0 90px 0;
    background-color: #F9F9FA;
}

.h2 {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    margin: 0 0 70px 0;
    display: inline-block;
    padding-left: 82px;
}

.h2::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -170px;
    width: 150px;
    height: 1px;
    background-color: #000;
}

.container {
    display: flex;
    position: relative;
    padding: 0 82px;
    margin-bottom: 70px;
    max-width: 1116px;
}

.container:nth-child(2):before {
    content: '';
    width: 680px;
    height: 561px;
    background-color: #DAF2F6;
    position: absolute;
    top: 163px;
    left: 50%;
    transform: translateX(-50%);
}

.img {
    position: relative;
    width: 680px;
}

.img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .3;
}

.img img {
    display: block;
    width: 100%;
}

.tx {
    position: absolute;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    width: 560px;
}

.tx h3 {
    color: #00A2C1;
    font-size: 1.5rem;
    margin: 0 0 30px 0;
}

.tx p {
    margin: 0;
    letter-spacing: .05em;
}

.container:nth-child(odd) {
    flex-direction: row-reverse;
    margin-left: auto;
}

.container:nth-child(odd) .tx {
    right: unset;
    left: 40px;
}

@media screen and (max-width: 768px) {
    .feature {
        padding: 70px 0 30px 0;
    }

    .h2 {
        font-size: 1.25rem;
        padding-left: 16px;
    }

    .h2::after {
        width: 100px;
        right: -120px;
    }

    .container {
        padding: 0 16px;
        flex-direction: column;
    }

    .container:nth-child(odd) {
        flex-direction: column;
    }

    .container:nth-child(2):before {
        width: 277px;
        left: 0;
        transform: unset;
        content: none;
    }

    .img {
        width: 100%;
        margin-bottom: 30px;
    }

    .tx {
        position: relative;
        top: unset;
        right: unset;
        transform: unset;
        width: 100%;
    }

    .container:nth-child(odd) .tx {
        left: unset;
    }

    .tx h3 {
        font-size: 1.25rem;
        margin-bottom: 20px;
    }

    .tx p {
        font-size: .875rem;
    }

    .pc_only {
        display: none;
    }
}