.campany {
    background-color: #DDE1E2;
    padding: 50px 0;
}

.container {
    padding: 0 82px;
}

.h3 {
    font-size: 1.125rem;
    margin: 0 0 20px 0;
}

.address {
    margin-bottom: 10px;
}

.address p {
    margin: 0;
}

.url a {
    display: inline-block;
    margin: 10px 0 0 5px;
    color: inherit;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0 16px;
    }

    .h3 {
        font-size: .875rem;
    }

    .address p {
        font-size: .75rem;
    }

    .url a {
        margin: 10px 0 0 0;
    }
}