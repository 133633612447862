.guide {
    padding: 70px 0 100px 0;
}

.container {
    padding: 0 82px;
}

.h2 {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    margin: 0 0 50px 0;
    display: inline-block;
}

.h2::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -170px;
    width: 150px;
    height: 1px;
    background-color: #000;
}

.dl {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.dl dt {
    width: 160px;
    margin: 0 60px 0 0;
    letter-spacing: .05em;
}

.dl dd {
    width: calc(100% - 250px);
    margin: 0 0 20px 0;
    letter-spacing: .05em;
}

.guide ul {
    padding-left: 1em;
}

@media screen and (max-width: 768px) {
    .guide {
        padding-bottom: 20px;
    }

    .h2 {
        font-size: 1.25rem;
        margin-bottom: 50px;
        padding-left: 0;
    }

    .h2::before {
        height: 30px;
        top: -40px;
    }

    .container {
        padding: 0 16px;
    }

    .dl {
        flex-direction: column;
    }

    .dl dt {
        width: 100%;
        margin-bottom: 20px;
        font-size: .875rem;
    }

    .dl dd {
        width: 100%;
        margin-bottom: 50px;
        font-size: .75rem;
    }
}