.usecase {
    padding-top: 70px;
    margin-bottom: 100px;
}

.container {
    padding: 0 82px;
    display: flex;
}

.card {
    width: calc(100% / 3);
    margin-right: 25px
}

.card:last-child {
    margin-right: 0;
}

.card__img {
    position: relative;
}

.card__img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .2;
}

.card__img img {
    display: block;
    width: 100%;
}

.card__tx p {
    margin: 20px 0 0 0;
    letter-spacing: .05em;
}

@media screen and (max-width: 768px) {
    .usecase {
        padding-top: 0;
    }

    .container {
        padding: 0 16px;
        flex-direction: column;
    }

    .card {
        width: 100%;
        margin: 0 0 50px 0;
    }

    .card__tx p {
        font-size: .875rem;
    }
}